import { createContext, useContext, FC, ReactNode } from 'react'
import {
  NEXT_PUBLIC_FHIR_WRAPPER_URL,
  NEXT_PUBLIC_ACCOUNT_SERVICE_URL,
  NEXT_PUBLIC_CONFIG_SERVICE_URL
} from '@constants'
import { useAuth } from './AuthProvider'
import axios, { AxiosInstance, AxiosError } from 'axios'
import * as Sentry from '@sentry/nextjs'
import { useToast } from '@chakra-ui/react'

interface Props {
  children: ReactNode
}

export enum Source {
  FHIR = 'FHIR',
  ACCOUNT = 'ACCOUNT',
  CONFIG = 'CONFIG',
  FHIR_V2 = 'FHIR_V2',
  ACCOUNT_V2 = 'ACCOUNT_V2',
  CONFIG_V2 = 'CONFIG_V2'
}

interface ContextValue {
  axiosInstanceFhir: AxiosInstance
  axiosInstanceAccountService: AxiosInstance
  axiosInstanceConfigService: AxiosInstance
  axiosInstanceFhirV2: AxiosInstance
  axiosInstanceAccountServiceV2: AxiosInstance
  axiosInstanceConfigServiceV2: AxiosInstance
}

const AxiosContext = createContext({} as ContextValue)

const AxiosProvider: FC<Props> = ({ children }) => {
  const toast = useToast()
  const { getUserFirebaseToken, token: jwtToken, logout } = useAuth()

  const firebaseToken = getUserFirebaseToken()

  const errorHandling = async (error: AxiosError, source: Source) => {
    if (error.response && (error.response.status === 403 || error.response.status === 401)) {
      toast.closeAll()
      toast({
        title: 'Session timeout',
        description: 'Please login again',
        status: 'error',
        duration: 5000,
        isClosable: true
      })

      return logout()
    } else {
      console.error(`contexts/AxiosProvider.tsx#${source}`, error)
      Sentry.withScope(function (scope) {
        scope.setLevel('debug')
        scope.setTag('source', source)

        // The exception has the event level set by the scope (info).
        Sentry.captureException(error)
      })
    }
    return Promise.reject(error as AxiosError)
  }

  /* 
  
    V2 uses JWT token for authentication

  */

  const axiosInstanceFhirV2 = axios.create({
    baseURL: NEXT_PUBLIC_FHIR_WRAPPER_URL,
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  })

  axiosInstanceFhirV2.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    async function (error) {
      await errorHandling(error, Source.FHIR_V2)
    }
  )

  const axiosInstanceAccountServiceV2 = axios.create({
    baseURL: NEXT_PUBLIC_ACCOUNT_SERVICE_URL,
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  })

  axiosInstanceAccountServiceV2.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    async function (error) {
      await errorHandling(error, Source.ACCOUNT_V2)
    }
  )

  const axiosInstanceConfigServiceV2 = axios.create({
    baseURL: NEXT_PUBLIC_CONFIG_SERVICE_URL,
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  })

  axiosInstanceConfigServiceV2.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    async function (error) {
      await errorHandling(error, Source.CONFIG_V2)
    }
  )

  const axiosInstanceFhir = axios.create({
    baseURL: NEXT_PUBLIC_FHIR_WRAPPER_URL,
    headers: {
      Authorization: `Bearer ${firebaseToken}`
    }
  })

  axiosInstanceFhir.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    async function (error) {
      await errorHandling(error, Source.FHIR)
    }
  )

  const axiosInstanceAccountService = axios.create({
    baseURL: NEXT_PUBLIC_ACCOUNT_SERVICE_URL,
    headers: {
      Authorization: `Bearer ${firebaseToken}`
    }
  })

  axiosInstanceAccountService.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    async function (error) {
      await errorHandling(error, Source.ACCOUNT)
    }
  )

  const axiosInstanceConfigService = axios.create({
    baseURL: NEXT_PUBLIC_CONFIG_SERVICE_URL,
    headers: {
      Authorization: `Bearer ${firebaseToken}`
    }
  })

  axiosInstanceConfigService.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    async function (error) {
      await errorHandling(error, Source.CONFIG)
    }
  )

  return (
    <AxiosContext.Provider
      value={{
        axiosInstanceFhir,
        axiosInstanceAccountService,
        axiosInstanceConfigService,
        axiosInstanceFhirV2,
        axiosInstanceAccountServiceV2,
        axiosInstanceConfigServiceV2
      }}
    >
      {children}
    </AxiosContext.Provider>
  )
}

const useAxios = () => useContext(AxiosContext)

export { AxiosProvider, useAxios }
