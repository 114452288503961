import { ReactNode, ReactElement, useEffect } from 'react'
import { useRouter } from 'next/router'

import { useAuth } from '@contexts/AuthProvider'
import FullPageLoader from './FullPageLoader'

interface Props {
  children: ReactNode
  protectedRoutes: string[]
}

export default function PrivateRoute({ protectedRoutes, children }: Props): ReactElement {
  const router = useRouter()
  const { isAuthenticated, isLoading } = useAuth()

  const pathIsProtected = protectedRoutes.indexOf(router.pathname) !== -1

  useEffect(() => {
    if (!isLoading && !isAuthenticated && pathIsProtected) {
      // Redirect route, you can point this to /login
      router.push('/login')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated, pathIsProtected])

  if ((isLoading || !isAuthenticated) && pathIsProtected) {
    return <FullPageLoader />
  }

  return <>{children}</>
}
