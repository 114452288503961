import React, { forwardRef, HTMLInputTypeAttribute } from 'react'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  InputProps as ChakraInputProps
} from '@chakra-ui/react'

export interface InputFormControlProps extends ChakraInputProps {
  ref?: React.Ref<HTMLInputElement>
  isRequired?: boolean
  label?: string
  placeholder?: string
  type: HTMLInputTypeAttribute
  id: string
  error?: string | undefined
  helperText?: string
  disabled?: boolean
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const FormControlContainer = forwardRef<HTMLInputElement, InputFormControlProps>((props, ref) => {
  const {
    isRequired,
    label,
    placeholder,
    type,
    id,
    error,
    helperText,
    value,
    handleChange,
    ...rest
  } = props

  return (
    <FormControl isInvalid={Boolean(error)} isRequired={isRequired}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <Input
        key={id}
        id={id}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={handleChange}
        ref={ref}
        {...rest}
      />
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
})

FormControlContainer.displayName = 'InputFormControl'

export default FormControlContainer
