import React, { ReactNode } from 'react'
import { Sidebar } from './Sidebar'
import { Content } from './Content'
import { Header } from './Header'
import { Grid, GridItem } from '@chakra-ui/react'


interface Props {
  children: ReactNode
}

const Layout: React.FC<Props> = ({ children }) => {
  const mainWidth = 'calc(100vw - var(--chakra-space-4))'

  return (
    <>
      <Sidebar />
      <Grid
        width="100%"
        height="100%"
        minHeight="100vh"
        m={0}
        p={0}
        backgroundColor="quredGray.600"
        templateAreas={`"header"
        "main"
        "footer"`}
        gridTemplateRows="var(--header-height) 1fr var(--footer-height)"
        gridTemplateColumns="1fr"
      >
        <GridItem area="header" width="100%">
          <Header />
        </GridItem>
        <GridItem area="main" width="100%" maxWidth={mainWidth} pr={8} pl={6} mt={6}>
          <Content>{children}</Content>
        </GridItem>
        <GridItem area="footer"></GridItem>
      </Grid>
    </>
  )
}

export default Layout
