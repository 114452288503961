export const EMPLOYEE_STATUS_CHANGED = (status: string) =>
  `Employee successfully ${status} analytics`

export const EMPLOYEE_STATUS_CHANGE_CONFIRMATION = (status: string, toFrom: string) =>
  `Are you sure you want to ${status} this user ${toFrom} analytics?`

export const EMPLOYEE_STATUS_DISCLAIMER_VALUE = (status: string) => `Yes, ${status}`

export const EMPLOYEE_STATUS_CHANGE_ERROR = (status: string) => `Error ${status} Employee,`

export const EMPLOYEE_ACTIVE_STATUS_CHANGE = 'Are you sure you want to deactivate this account?'