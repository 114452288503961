export enum RoleStatus {
  Active = 'Active',
  Draft = 'Draft',
  Deleted = 'Deleted'
}

interface Permission {
  name: string
  code: string
  description: string
}

export interface Role {
  id?: string
  name?: string
  code: string
  status: RoleStatus
  permissions?: Permission[] | []
  description?: string
  createdAt?: Date
  updatedAt?: Date
}
