export const NEXT_PUBLIC_FHIR_WRAPPER_URL = process.env.NEXT_PUBLIC_FHIR_WRAPPER_URL
export const NEXT_PUBLIC_ACCOUNT_SERVICE_URL = process.env.NEXT_PUBLIC_ACCOUNT_SERVICE_URL
export const NEXT_PUBLIC_CONFIG_SERVICE_URL = process.env.NEXT_PUBLIC_CONFIG_SERVICE_URL
export const B2B_IMPORT_USERS_TEMPLATE_FIREBASE_URL =
  process.env.NEXT_PUBLIC_B2B_IMPORT_USERS_TEMPLATE_FIREBASE_URL
export const isDevelopment =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'development' || process.env.VERCEL_ENV === 'development'
export const isLocal =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'local' || process.env.VERCEL_ENV === 'local'
export const isProduction =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' || process.env.VERCEL_ENV === 'production'
export const isPreview =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview' || process.env.VERCEL_ENV === 'preview'
export const RADIAL_CHART_MAIN_BAR_ID = 'summary'
export const NEXT_PUBLIC_GET_ADDRESS_IO_API_KEY = process.env.NEXT_PUBLIC_GET_ADDRESS_IO_API_KEY

export const USER_IMPORT_BUCKET = process.env.NEXT_PUBLIC_USER_IMPORT_BUCKET