import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  FC,
  useCallback,
  useEffect
} from 'react'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/nextjs'
import { isBrowser } from '@utils'

// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';
import { useToast } from '@chakra-ui/react'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { CustomUser } from 'constants/CustomUser'
import { CustomJwtToken } from 'constants/CustomJwtToken'
import { ROLES } from '@types'

interface Props {
  children: ReactNode
}

interface AuthContextValue {
  user: CustomUser | null
  login: ({ email, password }: { email: string; password: string }) => void
  logout: () => void
  isAuthenticated: boolean
  isLoading: boolean
  error: string
  getUserFirebaseToken: () => void
  token: string | null
  validateJwtToken: (jwtToken: string) => boolean
}

const AuthContext = createContext({} as AuthContextValue)

const AuthProvider: FC<Props> = ({ children }) => {
  const toast = useToast()
  const router = useRouter()

  const [user, setUser] = useState<CustomUser | null>(null)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [token, setToken] = useState<string | null>(
    (isBrowser() && localStorage.getItem('qtoken')) || null
  )
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')

  const resetAuth = useCallback(() => {
    setUser(null)
    setIsAuthenticated(false)
    setIsLoading(false)
    setError('')
    localStorage.removeItem('qtoken')
  }, [])

  const getUserFirebaseToken = () => {
    if (!token) {
      return null
    }

    const payload: CustomJwtToken = jwtDecode(token)

    const { firebaseToken } = payload

    return firebaseToken
  }

  const validateJwtToken = (jwtToken: string) => {
    const payload: CustomJwtToken = jwtDecode(jwtToken)

    const { exp } = payload

    const currentTime = Math.floor(Date.now() / 1000)

    if (!exp || exp < currentTime) {
      return false
    }

    return true
  }

  useEffect(() => {
    const jwtToken = localStorage.getItem('qtoken')

    if (jwtToken) {
      if (!validateJwtToken(jwtToken)) {
        resetAuth()
      } else {
        const payload: CustomJwtToken = jwtDecode(jwtToken)

        const { email, fhirID, firebaseID, organizationID, roles } = payload

        setUser({ email, fhirID, firebaseID, organizationID, roles })
        setToken(jwtToken)
        setIsLoading(false)
        setIsAuthenticated(true)
      }
    } else {
      resetAuth()
    }
  }, [])

  const login = useCallback(
    ({ email, password }: { email: string; password: string }) => {
      setIsLoading(true)
      setIsAuthenticated(false)

      axios
        .post(`${process.env.NEXT_PUBLIC_ACCOUNT_SERVICE_URL}/auth/login`, { email, password })
        .then(async (response) => {
          const { jwtToken } = response.data

          const payload: CustomJwtToken = jwtDecode(jwtToken)

          const { email, fhirID, firebaseID, organizationID, roles } = payload

          if (!roles?.some((role) => role === ROLES.Admin || role === ROLES.SuperAdmin)) {
            resetAuth()
            return toast({
              title: 'You are not authorized to use this portal',
              status: 'error',
              duration: 5000,
              isClosable: true
            })
          }

          Sentry.setUser({ email, roles })
          setToken(jwtToken)
          localStorage.setItem('qtoken', jwtToken)
          setUser({ email, fhirID, firebaseID, organizationID, roles })
          setIsLoading(false)
          setIsAuthenticated(true)
          Sentry.captureMessage('User logged into B2B Portal')
          router.push('/')
        })
        .catch(() => {
          toast({
            title: 'Error signing in',
            status: 'error',
            duration: 5000,
            isClosable: true
          })
          resetAuth()
        })
    },
    [router]
  )

  const logout = useCallback(() => {
    resetAuth()
    router.push('/login')
  }, [resetAuth, router])

  const value = {
    user,
    login,
    logout,
    isAuthenticated,
    isLoading,
    error,
    getUserFirebaseToken,
    token,
    validateJwtToken
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
