import React, { useCallback } from 'react'
import { HiMenu } from 'react-icons/hi'
import { Flex, Box, IconButton, Text } from '@chakra-ui/react'
import Image from 'next/image'
import styles from './Header.module.css'
import { ProfileMenu } from '@components/Header'
import { useAppContext } from '@contexts/AppProvider'
import { BsGear } from 'react-icons/bs'
import { useModal } from '@contexts/ModalProvider'
import { Select } from '@components/Modal/Company'

export const Header: React.FC = () => {
  const { setSidebarOpen, sidebarOpen, managingOrganization, isSuperAdmin } = useAppContext()
  const { openModal } = useModal()

  const handleConfigClick = useCallback(() => {
    openModal({
      header: 'Select Company',
      size: 'xl',
      children: <Select />
    })
  }, [openModal])

  return (
    <header className={styles.wrapper}>
      <Flex alignItems="center" w="100%" pl={4} pt={2} pr={4} pb={2}>
        <Flex alignItems="center" mt={2}>
          <IconButton
            mr={4}
            p={0}
            aria-label="menu"
            icon={<HiMenu size={24} />}
            variant="ghost"
            colorScheme="quredPurple"
            size="xs"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          />
          <Image src="/assets/qured-logo-full-blue.png" alt="Qured" width={70} height={28} />
        </Flex>
        <Flex ml="auto" alignItems="center" gap={3}>
          {isSuperAdmin && (
            <IconButton
              p={0}
              aria-label="Select Company"
              icon={<BsGear size={16} />}
              variant="ghost"
              colorScheme="quredPurple"
              size="xs"
              onClick={() => handleConfigClick()}
            />
          )}
          <Box>
            <Text as="b">{managingOrganization?.display}</Text>
          </Box>
          <ProfileMenu />
        </Flex>
      </Flex>
    </header>
  )
}
