import React, { FC, ReactNode, useEffect } from 'react'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
  Flex,
  CloseButton
} from '@chakra-ui/react'

export interface AlertItem extends AlertProps {
  status: 'info' | 'warning' | 'success' | 'error' | 'loading'
  title?: string
  description?: string | ReactNode
  icon?: boolean
  duration?: number
  autoDismiss?: boolean
}

interface AlertItemProps {
  alert: AlertItem
  removeAlert: (id?: string) => void
}

interface Props {
  alerts: AlertItem[]
  removeAlert: (id?: string) => void
}

const AlertItem = ({
  alert: { id, status, title, description, icon, duration = 3000, autoDismiss = true },
  removeAlert
}: AlertItemProps) => {
  useEffect(() => {
    if (autoDismiss) {
      const destructionTimer = setTimeout(() => {
        removeAlert()
      }, duration)
      return () => clearTimeout(destructionTimer)
    }
  }, [duration, autoDismiss, removeAlert])

  return (
    <Alert status={status} w="50%" m="0 auto" position="relative">
      {icon && <AlertIcon />}
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>{description}</AlertDescription>
      <CloseButton position="absolute" right={2} top={2} onClick={() => removeAlert(id)} />
    </Alert>
  )
}

const AlertContainer: FC<Props> = ({ alerts, removeAlert }) => {
  return (
    <Flex
      flexDirection="column"
      gap={4}
      position="fixed"
      bottom="20px"
      alignItems="center"
      justifyContent="center"
      w="100%"
      zIndex={1}
    >
      {alerts.map((alert) => {
        return <AlertItem key={alert.id} removeAlert={removeAlert} alert={alert} />
      })}
    </Flex>
  )
}

export default AlertContainer
