import React, { FC, ReactNode } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalProps as ChakraModalProps,
  Progress
} from '@chakra-ui/react'


export interface ModalItem extends Omit<ChakraModalProps, 'isOpen' | 'onClose'> {
  size?: ChakraModalProps['size']
  header: string
  primaryButtonText?: string
  secondaryButtonText?: string
  primaryAction?: () => void
  children: ReactNode
}

export interface ModalProps {
  isOpen: boolean
  onClose: () => void
  modal: ModalItem
  loading?: boolean
}

export const ModalContainer: FC<ModalProps> = ({ isOpen, onClose, modal, loading, ...rest }) => {
  const {
    secondaryButtonText = 'Cancel',
    primaryButtonText,
    primaryAction,
    size = 'md',
    header,
    children
  } = modal

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size} {...rest}>
      <ModalOverlay />
      <ModalContent p={2} position="relative">
        {loading && (
          <Progress size="xs" isIndeterminate position="absolute" top={0} w="100%" right={0} />
        )}
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton top={4} right={4} />
        <ModalBody>{children}</ModalBody>
        <ModalFooter
          display="flex"
          flexDirection="column"
          gap={6}
          width="100%"
          alignItems="stretch"
          pt={!primaryAction ? 2 : 4}
        >
          {primaryAction && <Button onClick={primaryAction}>{primaryButtonText}</Button>}
          <Button onClick={onClose} variant="secondary">
            {secondaryButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalContainer
