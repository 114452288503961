import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { FormHelperText, FormControl, Kbd, FormLabel } from '@chakra-ui/react'
import { StylesConfig, MultiValue } from 'react-select'
import Creatable from 'react-select/creatable'
import useSWR from 'swr'
import { Source } from '@contexts/AxiosProvider'
import { useAppContext } from '@contexts/AppProvider'

export interface Option {
  readonly value: string
  readonly label: string
  readonly color?: string
  readonly isFixed?: boolean
  readonly isDisabled?: boolean
}

const colourStyles: StylesConfig<Option> = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    borderColor: 'var(--chakra-colors-gray-300)'
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: 'var(--chakra-colors-quredGray-450)',
    borderRadius: 'var(--chakra-radii-full)'
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: 'white'
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: 'white',
    ':hover': {
      color: 'orange'
    }
  })
}

const AddTags = ({
  existingTags,
  setTags
}: {
  existingTags: string[]
  setTags: Dispatch<SetStateAction<string[]>>
}) => {
  const { orgId } = useAppContext()

  const { data } = useSWR(orgId ? [`companies/by-fhir/${orgId}`, Source.CONFIG] : null)

  const convertTagsToOptions = useCallback(
    (tags: string[]) =>
      tags?.map((tag) => {
        return {
          value: tag,
          label: tag
        } as Option
      }),
    []
  )

  const companyTags = useMemo(() => {
    if (data?.tags) {
      return convertTagsToOptions(data.tags)
    }
  }, [data, convertTagsToOptions])

  const handleChange = (newTags: MultiValue<Option>) => {
    setTags(newTags.map((tag) => tag.value))
  }

  const defaultTags = convertTagsToOptions(existingTags)
  return (
    <FormControl>
      <FormLabel>Tags</FormLabel>
      <Creatable
        defaultValue={defaultTags}
        options={companyTags}
        styles={colourStyles}
        isMulti
        onChange={handleChange}
      />
      <FormHelperText>
        Press <Kbd>Enter</Kbd> to add a tag. Press <Kbd>Backspace</Kbd> to remove the last tag.
      </FormHelperText>
    </FormControl>
  )
}

export default AddTags
