import React, { useContext, ReactNode, createContext, FC } from 'react'
// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';
import { initializeApp, FirebaseApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
}

interface Props {
  children: ReactNode
}

interface FirebaseContextValue {
  app: FirebaseApp
}

// Initialize Firebase Authentication and get a reference to the service
const app = initializeApp(firebaseConfig)

const FirebaseContext = createContext({} as FirebaseContextValue)

const FirebaseProvider: FC<Props> = ({ children }) => {
  const value = {
    app
  }

  return <FirebaseContext.Provider value={value}>{children}</FirebaseContext.Provider>
}

const useFirebase = () => useContext(FirebaseContext)

export { FirebaseProvider, useFirebase }
