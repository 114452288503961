import React from 'react'
import { Menu, MenuButton, MenuList, MenuItem, MenuGroup, Avatar } from '@chakra-ui/react'
import { useAppContext } from '@contexts/AppProvider'
import { getPatientName } from '@utils'
import { useAuth } from '@contexts/AuthProvider'

const ProfileMenu = () => {
  const { patient, isSuperAdmin, setDemo, demo } = useAppContext()
  const { logout } = useAuth()
  const { firstName, lastName } = patient
    ? getPatientName(patient)
    : { firstName: '', lastName: '' }

  return (
    <Menu>
      <MenuButton>
        <Avatar name={firstName + ' ' + lastName} size="sm" bgColor="orange" />
      </MenuButton>
      <MenuList>
        <MenuGroup title="Profile">
          {isSuperAdmin && (
            <MenuItem onClick={() => setDemo(!demo)}>
              {`${demo ? 'Deactivate' : 'Activate'} Demo`}
            </MenuItem>
          )}
          <MenuItem onClick={() => logout()}>Log Out</MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  )
}

export default ProfileMenu
