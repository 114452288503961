export enum ROLES {
  Super = 'Super',
  SuperAdmin = 'SuperAdmin',
  Admin = 'Admin',
  HealthAdvisor = 'HealthAdvisor',
  SupportTeam = 'SupportTeam'
}

export type RoleDetails = {
  active: boolean
  code: ROLES
  createdAt: string
  description: string | null
  name: string
  roleId: string
  updatedAt: string
  userId: string
}

export type User = {
  firebaseUserId: string
  roles: RoleDetails[]
  id: string
  organizationId: string
  phoneVerified: boolean
  tags: string[]
  createdAt: string
  updatedAt: string
}
