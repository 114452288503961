import React, { forwardRef } from 'react'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Select,
  SelectProps as ChakraSelectProps
} from '@chakra-ui/react'

export interface SelectOption {
  value: string
  label: string
}

export interface SelectFormControlProps extends ChakraSelectProps {
  ref?: React.Ref<HTMLSelectElement>
  isRequired?: boolean
  label?: string
  placeholder?: string
  type: 'select'
  id: string
  error?: string | undefined
  helperText?: string
  options: SelectOption[]
  disabled?: boolean
  handleChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

const FormControlContainer = forwardRef<HTMLSelectElement, SelectFormControlProps>((props, ref) => {
  const {
    isRequired,
    label,
    placeholder,
    type,
    id,
    error,
    helperText,
    value,
    options,
    handleChange,
    ...rest
  } = props

  return (
    <FormControl isInvalid={Boolean(error)} isRequired={isRequired}>
      {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <Select
        key={id}
        id={id}
        placeholder={placeholder}
        type={type}
        value={value}
        ref={ref}
        onChange={handleChange}
        {...rest}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
})

FormControlContainer.displayName = 'SelectFormControl'

export default FormControlContainer
