import React, { useState, useEffect, useMemo } from 'react'
import { Stack, Radio, Flex, Button, RadioGroup } from '@chakra-ui/react'
import { InputFormControl } from '@components/Form'
import { useModal } from '@contexts/ModalProvider'
import useSWR from 'swr'
import { Company } from '@types'
import { useAppContext } from '@contexts/AppProvider'
import { Source } from '@contexts/AxiosProvider'

const CompanySelect = () => {
  const { setManagingOrganization } = useAppContext()
  const { data } = useSWR([`/companies/v2`, Source.CONFIG_V2])
  //take query param is responsible for pagination. It is set to 100. This is a temporary solution. We need to implement scrolling pagination in the future or make an endpoint return all companies.
  const companiesArray = useMemo(() => (data?.data as Company[]) || [], [data])
  const [search, setSearch] = useState('')
  const [companies, setCompanies] = useState(companiesArray || [])
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null)
  const { closeModal } = useModal()

  useEffect(() => {
    if (search) {
      const filteredCompanies = companiesArray.filter((company) =>
        company.name.toLowerCase().includes(search.toLowerCase())
      )
      setCompanies(filteredCompanies)
    } else {
      setCompanies(companiesArray)
    }
  }, [search, companiesArray])

  return (
    <Flex direction="column" gap={4}>
      <InputFormControl
        placeholder="Search"
        id="search"
        type="input"
        onChange={(e) => setSearch(e.target?.value)}
      />
      <RadioGroup>
        <Stack spacing={4} pl={2} pr={2} overflow="auto" maxHeight="328px" minHeight="200px">
          {companies.map((company) => (
            <Radio
              size="lg"
              key={company.id}
              spacing={4}
              borderColor="quredGray.300"
              value={company.name}
              onChange={() => {
                setSelectedCompany(company)
              }}
            >
              {company.name}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
      <Button
        disabled={!selectedCompany}
        onClick={() => {
          if (selectedCompany) {
            const managingOrganization = {
              display: selectedCompany.name,
              reference: `Organization/${selectedCompany.organizationId}`
            }
            setManagingOrganization(managingOrganization)
            closeModal()
          }
        }}
      >
        Select
      </Button>
    </Flex>
  )
}

export default CompanySelect
