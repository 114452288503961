import React, { ReactNode } from 'react'
import { Flex } from '@chakra-ui/react'
import { Tag } from '@components/Misc'
import uniqueId from 'lodash.uniqueid'

export const CustomTag = ({ children }: { children: ReactNode }) => {
  return (
    <Tag
      size="sm"
      color="quredGray.450"
      borderRadius="full"
      borderColor="var(--chakra-colors-quredGray-450)"
      backgroundColor="white"
      variant="outline"
    >
      {children}
    </Tag>
  )
}

const CustomTagRow = ({ tags }: { tags: string[] }) => {
  return (
    <Flex flexWrap="wrap" gap={1}>
      {tags && tags.length >= 2 ? (
        <>
          <CustomTag>{tags[0]}</CustomTag>
          <CustomTag>+ {tags.length - 1}</CustomTag>
        </>
      ) : tags && tags.length === 1 ? (
        tags.map((tag: string) => tag && <CustomTag key={uniqueId(tag)}>{tag}</CustomTag>)
      ) : null}
    </Flex>
  )
}

export default CustomTagRow
