import React, { ReactElement } from 'react'
import { SWRConfig } from 'swr'
import { useAuth } from '@contexts/AuthProvider'
import { Source, useAxios } from '@contexts/AxiosProvider'
import { useAlert } from './AlertProvider'

const SWRConfigWrapper = ({ children }: { children: ReactElement }) => {
  const { getUserFirebaseToken } = useAuth()
  const {
    axiosInstanceFhir,
    axiosInstanceAccountService,
    axiosInstanceConfigService,
    axiosInstanceFhirV2,
    axiosInstanceAccountServiceV2,
    axiosInstanceConfigServiceV2
  } = useAxios()
  const { notify } = useAlert()

  return (
    <SWRConfig
      value={{
        fetcher: (resource: string, source: Source) => {
          let axiosInstance
          switch (source) {
            case Source.ACCOUNT:
              axiosInstance = axiosInstanceAccountService
              break
            case Source.CONFIG:
              axiosInstance = axiosInstanceConfigService
              break
            case Source.FHIR:
              axiosInstance = axiosInstanceFhir
              break
            case Source.FHIR_V2:
              axiosInstance = axiosInstanceFhirV2
              break
            case Source.ACCOUNT_V2:
              axiosInstance = axiosInstanceAccountServiceV2
              break
            case Source.CONFIG_V2:
              axiosInstance = axiosInstanceConfigServiceV2
              break
            default:
              break
          }
          return resource && axiosInstance?.get(resource).then((res) => res.data)
        },
        onError: async (error) => {
          if (error.response && error.response.status === 403) {
            getUserFirebaseToken()
          } else {
            notify({
              status: 'error',
              title: 'Error',
              description: 'An error occurred while fetching data. Please try again later.'
            })
          }
        }
      }}
    >
      {children}
    </SWRConfig>
  )
}

export { SWRConfigWrapper }
