import React from 'react'
import styles from './Sidebar.module.css'
import {
  List,
  ListItem,
  Box,
  Flex,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerCloseButton,
  DrawerHeader
} from '@chakra-ui/react'
import NextLink from 'next/link'
import { GreenLine } from '@components/Misc'
import { useRouter } from 'next/router'
import { CommonIcon } from '@components/Icon/CommonIcon'
import { useAppContext } from '@contexts/AppProvider'
import Image from 'next/image'
import cn from 'classnames'

const NavItem = ({
  currentPathname,
  href,
  label,
  icon
}: {
  currentPathname: string
  href: string
  label: string
  icon: string
}) => {
  const { setSidebarOpen } = useAppContext()
  const selected = currentPathname === href
  const linkClassName = cn(styles.navItem, selected && styles.selected)
  const iconWrapperClassName = cn(selected && styles.selected)

  return (
    <>
      <ListItem
        position="relative"
        display="flex"
        _hover={{ color: 'var(--chakra-colors-quredBlue-600)' }}
        onClick={() => {
          setSidebarOpen(false)
        }}
      >
        {selected && (
          <Box position="absolute" top={-2}>
            <GreenLine width={4} height={40} />
          </Box>
        )}
        <Flex ml={6} mr={3} className={iconWrapperClassName}>
          <CommonIcon name={icon} size={24} />
        </Flex>
        <NextLink href={href} passHref>
          <a className={linkClassName}>{label}</a>
        </NextLink>
      </ListItem>
    </>
  )
}

export const Sidebar = () => {
  const { pathname } = useRouter()
  const { sidebarOpen, setSidebarOpen } = useAppContext()
  return (
    <Drawer onClose={() => setSidebarOpen(false)} isOpen={sidebarOpen} placement="left" size="xs">
      <DrawerOverlay />
      <DrawerContent
        borderTopEndRadius="xl"
        borderEndEndRadius="xl"
        mt={4}
        maxWidth="var(--sidebar-width)"
      >
        <DrawerHeader>
          <Image src="/assets/qured-logo-full-blue.png" alt="Qured" width={70} height={28} />
          <DrawerCloseButton />
        </DrawerHeader>
        <DrawerBody>
          <nav className={styles.wrapper}>
            <List spacing={6} mt={4} ml={-2}>
              <NavItem currentPathname={pathname} href="/" label="Dashboard" icon="dashboard" />
              <NavItem currentPathname={pathname} href="/employees" label="My Team" icon="team" />
              <NavItem currentPathname={pathname} href="/admins" label="Admins" icon="key" />
              {/* <NavItem
                currentPathname={pathname}
                href="/billing"
                label="Billing"
                icon="credit-card"
              /> */}
            </List>
          </nav>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
