import React, {
  ReactNode,
  createContext,
  useContext,
  FC,
  useState,
  useMemo,
  useCallback
} from 'react'
import { Alert } from '@components/Alert'
import { AlertItem } from '@components/Alert/AlertContainer'
import uniqueId from 'lodash.uniqueid'

interface Props {
  children: ReactNode
}

interface ContextValue {
  notify: (alert: AlertItem) => void
}

const AlertContext = createContext({} as ContextValue)

const AlertProvider: FC<Props> = ({ children }) => {
  const [alerts, setAlerts] = useState<AlertItem[] | null>(null)

  const notify = useCallback(
    (alert: AlertItem) => {
      const id = uniqueId('alert_')
      const newAlert = { ...alert, id }
      const newAlerts = alerts ? [...alerts, newAlert] : [newAlert]
      setAlerts(newAlerts)
    },
    [alerts]
  )

  const removeAlert = useCallback(
    (id?: string) => {
      if (alerts) {
        if (id) {
          const newAlerts = alerts.filter((alert) => alert.id !== id)
          setAlerts(newAlerts)
        } else {
          const newAlerts = alerts.slice(0, -1)
          setAlerts(newAlerts)
        }
      }
    },
    [alerts, setAlerts]
  )

  const value = useMemo(() => ({ notify }), [notify])

  return (
    <AlertContext.Provider value={value}>
      {children}
      {alerts && <Alert alerts={alerts} removeAlert={removeAlert} />}
    </AlertContext.Provider>
  )
}

const useAlert = () => useContext(AlertContext)

export { AlertProvider, useAlert }
