import React, { ReactNode, FC } from 'react'
import { useRouter } from 'next/router'
import { Heading, Flex } from '@chakra-ui/react'
import { CommonIcon } from '@components/Icon/CommonIcon'

interface Props {
  children?: ReactNode
  title?: string
  icon?: string
}

export const SectionHeader: FC<Props> = ({ children, title, icon }) => {
  const { pathname } = useRouter()
  const sanitizedPathname = pathname.replace(/^\//, '')
  const header = title || sanitizedPathname

  return (
    <Flex justifyContent="space-between" position="relative" alignItems="center" mb={4}>
      <Flex alignItems="center" ml={1} gap={2}>
        {icon && <CommonIcon name={icon} size={24} />}
        <Heading as="h2" size="lg" textTransform="capitalize">
          {header}
        </Heading>
      </Flex>
      {children}
    </Flex>
  )
}

export default SectionHeader
