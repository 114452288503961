import React, { FC } from 'react'
import { HiOutlineCreditCard, HiOutlineDeviceMobile } from 'react-icons/hi'
import { RxDashboard } from 'react-icons/rx'
import { FiUsers, FiKey, FiUser, FiTrash } from 'react-icons/fi'
import { GiVial } from 'react-icons/gi'
import { IoMdGift } from 'react-icons/io'

export interface CommonIconProps {
  name: string
  size: number
  color?: string
}

type IconType = FC<CommonIconProps>

const nameToIconMapping: { [key: string]: IconType } = {
  dashboard: RxDashboard,
  team: FiUsers,
  user: FiUser,
  key: FiKey,
  'credit-card': HiOutlineCreditCard,
  mobile: HiOutlineDeviceMobile,
  vial: GiVial,
  trash: FiTrash,
  offering: IoMdGift
}

export const CommonIcon: FC<CommonIconProps> = ({ name, size, color }) => {
  const IconComponent = nameToIconMapping[name] || null

  if (!IconComponent)
    throw new Error("This icon name doesn't exist. Please, add an icon or select an existing one.")

  return <IconComponent name={name} size={size} color={color} />
}
