import React, { FC, RefObject } from 'react'
import { Text, Flex } from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'
import styles from './CustomFileInputLabel.styles.module.css'

const filePlaceholder = 'No file selected'

interface Props {
  fileInputRef: RefObject<HTMLLabelElement>
  fileName?: string
  helperText: string
  handleClick?: () => void
}

const CustomFileInputLabel: FC<Props> = ({ fileInputRef, fileName, helperText, handleClick }) => {
  return (
    <label className={styles.fileInputLabel} htmlFor="file-input" ref={fileInputRef}>
      <Text color="quredGray.300" as="b" fontSize="xs" mb={1}>
        {helperText}
      </Text>
      <Flex border="1px solid" borderColor="quredGray.500" borderRadius="lg">
        <Button
          size="sm"
          variant="secondary"
          m={1}
          onClick={() => {
            if (handleClick) handleClick()
            fileInputRef?.current?.click()
          }}
        >
          Browse...
        </Button>
        <Text color="quredGray.300" alignSelf="center" ml={1} noOfLines={1}>
          {fileName || filePlaceholder}
        </Text>
      </Flex>
    </label>
  )
}

export default CustomFileInputLabel
