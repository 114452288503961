import React from 'react'
import { Flex } from '@chakra-ui/react'
import { ImSpinner5 } from 'react-icons/im'
import styles from './FullPageLoader.module.css'

const FullPageLoader = () => {
  return (
    <Flex
      w="100vw"
      h="100vh"
      backgroundColor="quredGray.600"
      alignItems="center"
      justifyContent="center"
    >
      <ImSpinner5 size="100px" className={styles.spinner} />
    </Flex>
  )
}

export default FullPageLoader
