import React, {
  FC,
  useContext,
  createContext,
  useState,
  ReactNode,
  useCallback,
  Dispatch,
  SetStateAction
} from 'react'
import { Modal } from '@components/Modal'
import { ModalItem } from '@components/Modal/ModalContainer'

interface Props {
  children: ReactNode
}

interface ModalContextValue {
  openModal: (modalDetails: ModalItem) => void
  closeModal: () => void
  setLoading: Dispatch<SetStateAction<boolean>>
  loading: boolean
}

const ModalContext = createContext({} as ModalContextValue)

const ModalProvider: FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [modal, setModal] = useState({} as ModalItem)
  const [loading, setLoading] = useState(false)

  const openModal = useCallback((modalDetails: ModalItem) => {
    setModal(modalDetails)
    setIsOpen(true)
  }, [])

  const closeModal = useCallback(() => setIsOpen(false), [])

  return (
    <ModalContext.Provider value={{ openModal, closeModal, loading, setLoading }}>
      <Modal isOpen={isOpen} onClose={closeModal} modal={modal} loading={loading} />
      {children}
    </ModalContext.Provider>
  )
}

const useModal = () => useContext(ModalContext)

export { ModalProvider, useModal }
