import React, { FC } from 'react'
import { InputFormControlProps } from './InputFormControl'
import { SelectFormControlProps } from './SelectFormControl'
import { SelectFormControl } from '@components/Form'
import { InputFormControl } from '@components/Form'

export type CustomFieldType = SelectFormControlProps | InputFormControlProps

interface CustomFormControllerProps {
  field: CustomFieldType
  value?: string | number | readonly string[] | undefined
  error?: string | undefined
  handleChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
}

const CustomFormController: FC<CustomFormControllerProps> = ({
  field,
  value,
  error,
  handleChange
}) => {
  const selectField = field as SelectFormControlProps

  return field.type !== 'select' ? (
    <InputFormControl
      key={field.id}
      id={field.id}
      label={field.label}
      name={field.id}
      type={field.type}
      isRequired={field.isRequired}
      isDisabled={field.disabled}
      placeholder={field.placeholder}
      helperText={field.helperText}
      value={value}
      onChange={handleChange}
      ref={field.ref as React.RefObject<HTMLInputElement>}
      error={error}
    />
  ) : (
    <SelectFormControl
      key={selectField.id}
      id={selectField.id}
      name={selectField.id}
      type={selectField.type}
      isRequired={selectField.isRequired}
      isDisabled={selectField.disabled}
      placeholder={selectField.placeholder}
      helperText={selectField.helperText}
      label={selectField.label}
      options={selectField.options}
      value={value}
      onChange={handleChange}
      ref={selectField.ref}
      error={error}
    />
  )
}

export default CustomFormController
