import get from 'lodash.get'
import find from 'lodash.find'
import set from 'lodash.set'
import { Identifier, Extension, Patient, ContactDetail, ContactPoint } from 'fhir/r4'
import { GENERIC_CODE_SYSTEM_URL } from '@constants'
import { PatientFormValues } from '@components/Modal/Employee/AddOrEdit'
import {
  NHS_FHIR_CODE,
  NHS_FHIR_DISPLAY,
  PMI_ID_FHIR_CODE,
  PMI_ID_DISPLAY,
  TAGS_FHIR_URL,
  NHS_FHIR_SYSTEM
} from '@constants'

type Iteratee = {
  [key: string]: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const getPatientName = (fhirData: Patient) => {
  const firstName = get(fhirData, 'name[0].given[0]') || ''
  const middleName = get(fhirData, 'name[0].given[1]') || ''
  const lastName = get(fhirData, 'name[0].family') || ''
  return {
    firstName,
    middleName,
    lastName
  }
}

export const getPatientDob = (fhirData: Patient) => {
  const dob = get(fhirData, 'birthDate')
  return dob
}

export const getPatientProperty = (fhirData: Patient, property: string) => {
  return get(fhirData, property)
}

export const findPatientProperty = (
  fhirData: Patient,
  propertyToFind: string,
  iterateeValue: Iteratee
) => {
  const property = get(fhirData, propertyToFind)
  const result = find(property, iterateeValue)
  return result && result.value
}

export const getPatientIdentifier = (fhirData: Patient, identifierCode: string) => {
  const identifiersArray = get(fhirData, 'identifier')

  if (!identifiersArray) {
    return null
  }

  const result = identifiersArray.find((identifier: Identifier) => {
    if (
      identifier.type &&
      identifier.type.coding &&
      identifier.type.coding[0].code === identifierCode
    ) {
      return identifier
    }
  })
  return result && result.value
}

export const getPatinetExtension = (fhirData: Patient, extensionURL: string) => {
  const extensions = get(fhirData, 'extension')
  if (!extensions) {
    return null
  }

  const result = extensions.find((extension: Extension) => {
    if (extension?.url === extensionURL) {
      return extension
    }
  })?.valueString
  return result
}

export const setPatientProperty = (fhirData: Patient, property: string, value: unknown) => {
  return set(fhirData, property, value)
}

export const setPatientIdentifier = ({
  fhirData,
  identifierCode,
  value,
  system,
  display
}: {
  fhirData: Patient
  identifierCode: string
  value: string
  system?: string
  display?: string
}) => {
  const identifiersArray = get(fhirData, 'identifier') || []

  const result = identifiersArray.find((identifier: Identifier) => {
    if (
      identifier.type &&
      identifier.type.coding &&
      identifier.type.coding[0].code === identifierCode
    ) {
      return identifier
    }
  })

  if (!result) {
    const identifier = createPatientIdentifier(identifierCode, value, system, display)
    identifiersArray.push(identifier)
    return set(fhirData, 'identifier', identifiersArray)
  }

  set(result, 'value', value)
  set(fhirData, 'identifier', identifiersArray)
  return fhirData
}

export const setPatientExtension = (
  fhirData: Patient,
  extensionURL: string,
  value: string
): Patient => {
  const extensions = get(fhirData, 'extension')

  if (!extensions) {
    return set(fhirData, 'extension', [
      {
        url: extensionURL,
        valueString: value
      }
    ])
  }

  const index = extensions.findIndex((extension: Extension) => {
    if (extension?.url === extensionURL) {
      return extension
    }
  })

  if (index === -1) {
    extensions.push({
      url: extensionURL,
      valueString: value
    })
    return set(fhirData, 'extension', extensions)
  }

  set(extensions[index], 'valueString', value)
  return set(fhirData, 'extension', extensions)
}

export const findAndSetPatientContactPoint = (fhirData: Patient, contactDetail: ContactDetail) => {
  const contactPoints = get(fhirData, 'telecom')
  if (!contactPoints) {
    return set(fhirData, 'telecom', contactDetail.telecom)
  }

  const result = contactPoints.find((contactPoint: ContactPoint) => {
    if (
      contactDetail.telecom &&
      contactPoint.system === contactDetail.telecom[0].system &&
      contactPoint.use === contactDetail.telecom[0].use
    ) {
      return contactPoint
    }
  })

  if (!result && contactDetail.telecom) {
    contactPoints.push(contactDetail.telecom[0])
    return set(fhirData, 'telecom', contactPoints)
  }

  if (contactDetail.telecom && result) {
    return set(result, 'value', contactDetail.telecom[0].value)
  }
}

export const createPatientIdentifier = (
  identifierCode: string,
  value: string,
  system?: string,
  display?: string
): Identifier => {
  return {
    type: {
      coding: [
        {
          code: identifierCode,
          system: GENERIC_CODE_SYSTEM_URL,
          display
        }
      ]
    },
    system: system,
    value: value
  }
}

export const createPatient = ({
  employee,
  updatedEmployeeValues
}: {
  employee: Patient
  updatedEmployeeValues: PatientFormValues
}): Patient => {
  const updatedPatient = setPatientProperty(
    employee,
    'name[0].given[0]',
    updatedEmployeeValues.firstName
  )
  setPatientProperty(updatedPatient, 'name[0].given[1]', updatedEmployeeValues.middleName)
  setPatientProperty(updatedPatient, 'name[0].family', updatedEmployeeValues.lastName)
  setPatientProperty(updatedPatient, 'gender', updatedEmployeeValues.gender)
  setPatientProperty(updatedPatient, 'birthDate', updatedEmployeeValues.birthDate)
  findAndSetPatientContactPoint(updatedPatient, {
    telecom: [
      {
        system: 'phone',
        value: updatedEmployeeValues.phoneNumber,
        use: 'mobile'
      }
    ]
  })
  findAndSetPatientContactPoint(updatedPatient, {
    telecom: [
      {
        system: 'email',
        value: updatedEmployeeValues.email,
        use: 'work'
      }
    ]
  })
  setPatientProperty(updatedPatient, 'address[0].city', updatedEmployeeValues.city)
  setPatientProperty(updatedPatient, 'address[0].line[0]', updatedEmployeeValues.address1)
  setPatientProperty(updatedPatient, 'address[0].line[1]', updatedEmployeeValues.address2)
  setPatientProperty(updatedPatient, 'address[0].postalCode', updatedEmployeeValues.postcode)
  setPatientProperty(updatedPatient, 'address[0].country', updatedEmployeeValues.country)
  setPatientIdentifier({
    fhirData: updatedPatient,
    identifierCode: PMI_ID_FHIR_CODE,
    value: updatedEmployeeValues.insuranceMemberId || '',
    display: PMI_ID_DISPLAY
  })
  setPatientIdentifier({
    fhirData: updatedPatient,
    identifierCode: NHS_FHIR_CODE,
    value: updatedEmployeeValues.nhsNumber || '',
    system: NHS_FHIR_SYSTEM,
    display: NHS_FHIR_DISPLAY
  })

  if (updatedEmployeeValues.tags && updatedEmployeeValues.tags.length) {
    setPatientExtension(updatedPatient, TAGS_FHIR_URL, updatedEmployeeValues.tags?.join(','))
  } else {
    setPatientExtension(updatedPatient, TAGS_FHIR_URL, '')
  }

  return updatedPatient
}