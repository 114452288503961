import React, {
  ReactNode,
  createContext,
  useContext,
  FC,
  useState,
  Dispatch,
  SetStateAction,
  useMemo
} from 'react'
import { useAuth } from './AuthProvider'
import useSWR from 'swr'
import { Patient, Reference } from 'fhir/r4'
import { useEffect } from 'react'
import { getPatientProperty, extractOrgId } from '@utils'
import { Source } from '@contexts/AxiosProvider'
import { ROLES } from '@types'

interface Props {
  children: ReactNode
}

interface ContextValue {
  patient: Patient | null
  managingOrganization: Reference | null
  setManagingOrganization: Dispatch<SetStateAction<Reference | null>>
  sidebarOpen: boolean
  setSidebarOpen: Dispatch<SetStateAction<boolean>>
  isSuperAdmin: boolean
  orgId: string | null
  demo: boolean
  setDemo: Dispatch<SetStateAction<boolean>>
}

const AppContext = createContext({} as ContextValue)

const AppProvider: FC<Props> = ({ children }) => {
  const { user } = useAuth()
  const shouldFetch = user && user.firebaseID
  const { data } = useSWR(shouldFetch ? [`/patients/v2/${user.firebaseID}`, Source.FHIR_V2] : null)
  const [managingOrganization, setManagingOrganization] = useState<Reference | null>(null)
  const [demo, setDemo] = useState(false)

  const patient = useMemo(() => {
    return data && data.entry && data.entry.length ? (data.entry[0].resource as Patient) : null
  }, [data])

  const exctractedOrg: Reference = useMemo(() => {
    if (patient) {
      return getPatientProperty(patient, 'managingOrganization')
    }
  }, [patient])

  const [sidebarOpen, setSidebarOpen] = useState(false)

  const validateQuredEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@qured\.com$/

    return re.test(String(email).toLowerCase())
  }

  const isSuperAdmin = useMemo(() => {
    if (user?.roles) {
      return user.roles.includes(ROLES.SuperAdmin) && validateQuredEmail(user.email)
    }

    return false
  }, [user?.roles, user?.email])

  const orgId = useMemo(() => {
    if (managingOrganization && managingOrganization.reference) {
      return extractOrgId(managingOrganization.reference as string)
    }
    return null
  }, [managingOrganization])

  const value = {
    patient,
    managingOrganization,
    setManagingOrganization,
    sidebarOpen,
    setSidebarOpen,
    isSuperAdmin,
    orgId,
    demo,
    setDemo
  }

  useEffect(() => {
    if (exctractedOrg) {
      setManagingOrganization(exctractedOrg)
    }
  }, [exctractedOrg])

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

const useAppContext = () => useContext(AppContext)

export { AppProvider, useAppContext }
