import { ReactNode, ReactElement } from 'react'
import type { AppProps } from 'next/app'
import type { NextPage } from 'next'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from '@components/theme'
import { AuthProvider } from '@contexts/AuthProvider'
import { AppProvider } from '@contexts/AppProvider'
import { FirebaseProvider } from '@contexts/FirebaseProvider'
import { ModalProvider } from '@contexts/ModalProvider'
import { PrivateRoute } from '@components/Layout'
import { SWRConfigWrapper } from '@contexts/SWRConfigWrapper'
import { AxiosProvider } from '@contexts/AxiosProvider'
import { AlertProvider } from '@contexts/AlertProvider'

import 'custom-css-vars.css'

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page)

  const protectedRoutes = [
    '/',
    '/employees',
    '/employees/:id',
    '/onboarding',
    '/admins',
    '/billing'
  ]

  return (
    <ChakraProvider theme={theme}>
      <AlertProvider>
        <FirebaseProvider>
          <AuthProvider>
            <AxiosProvider>
              <SWRConfigWrapper>
                <AppProvider>
                  <ModalProvider>
                    <PrivateRoute protectedRoutes={protectedRoutes}>
                      {getLayout(<Component {...pageProps} />)}
                    </PrivateRoute>
                  </ModalProvider>
                </AppProvider>
              </SWRConfigWrapper>
            </AxiosProvider>
          </AuthProvider>
        </FirebaseProvider>
      </AlertProvider>
    </ChakraProvider>
  )
}
