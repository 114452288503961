import React, { FC, ReactNode } from 'react'
import { Tag, TagProps, TagCloseButton } from '@chakra-ui/react'

interface Props extends TagProps {
  children: ReactNode
  color?: string
  size?: 'sm' | 'md' | 'lg' | 'xl'
  borderRadius?: 'full' | 'lg' | 'md' | 'sm' | 'xl' | 'none'
  borderColor?: string
  backgroundColor?: string
  variant?: 'solid' | 'outline' | 'ghost'
  closeButton?: boolean
  closeButtonAction?: () => void
  onClick?: () => void
}

const Container: FC<Props> = ({
  children,
  color,
  size = 'md',
  borderColor,
  borderRadius,
  backgroundColor,
  variant,
  closeButton,
  closeButtonAction,
  onClick,
  ...props
}) => {
  return (
    <Tag
      size={size}
      color={color}
      borderRadius={borderRadius}
      boxShadow={`inset 0 0 0px 1px ${borderColor}`}
      backgroundColor={backgroundColor}
      variant={variant}
      {...props}
      onClick={onClick}
    >
      {children}
      {closeButton && <TagCloseButton onClick={closeButtonAction} />}
    </Tag>
  )
}

export default Container
